import './App.css';

function App() {
  return (
    <div className="application">
      <h1>design.surajgoraya.ca</h1>
      <h3>coming soon.</h3>
    </div>
  );
}

export default App;
